<template>
  <div class="section_secondary_heading">
    <div class="secondary_heading">
      {{ content }}
      <span class="extra">
        {{ extra_content }}
      </span>
    </div>
    
    <v-divider v-if="hr"></v-divider>
  </div>
</template>

<script>
export default {
  name: "SectionSecondaryHeading",
  props: {
    content: String,
    extra_content: String,
    hr: Boolean,
  },

  data: () => ({}),
};
</script>

<style lang="scss">
.section_secondary_heading {
  .secondary_heading {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    padding: 35px;
    color: var(--v-secondary-base);

    display: flex;
    flex-direction: column;

    .extra {
      font-family: "Ubuntu";
      font-weight: 400;
      margin-top: 5px;
      font-size: 16px;
      color: var(--v-section_font_color-base);
    }
  }
}
</style>