<template>
  <div class="section_heading">
    <div class="heading" style="flex-wrap: wrap">
      <h4>{{ content }}</h4>

      <div class="d-flex align-center" style="min-width:200px; flex-wrap:wrap">
        <div
          v-for="extra in extra_content"
          v-bind:key="extra.type"
          class="extra"
        >
          <span
            v-if="extra.type == 'text'"
            :style="'border: 1px solid; padding: 5px 8px; color: ' + extra.color"
          >
            {{ extra.text }}
          </span>

          <v-text-field
            v-if="extra.type == 'search'"
            v-on:input="typeSearch"
            append-icon="mdi-magnify"
            dense
            outlined
            rounded
            placeholder="Search"
            hide-details
            required
          ></v-text-field>

          <v-btn v-if="extra.type == 'button'" @click="btnClick" :color="extra.color" :style="'color: ' + extra.font_color" rounded>
            <v-icon v-if="extra.icon">{{ extra.icon }}</v-icon> {{ extra.text }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: "SectionHeading",
  props: {
    content: String,
    extra_content: Array,
  },

  methods: {
    typeSearch(e) {
      this.$emit("search", e);
    },

    btnClick(e) {
      this.$emit("btnClick", e);
    },
  },

  data: () => ({}),
};
</script>

<style lang="scss">
.section_heading {
  .heading {
    font-family: "Poppins";
    font-size: 26px;
    padding: 35px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 10px;
    }

    .extra {
      user-select: none;
      font-size: 14px;
      margin:10px 10px;
    }
  }
}
</style>